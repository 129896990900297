import React from 'react'
import styled from '@emotion/styled'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Icon = styled.img`
  height: 16px;
  margin: 2px 3px -2px;
`

const IconLinkWrapper = styled(OutboundLink)`
  font-family: inherit;
  color: #000000;
  text-decoration: none;

  border-radius: 4px;
  padding: 4px 1px;
  margin: -4px 0;

  &:hover {
    text-decoration: none;
    background-color: #dadada;
  }
`

interface IconLinkProps {
  icon: string
  to: string
  text: string
}

const IconLink: React.FC<IconLinkProps> = ({
  icon,
  to,
  text
}: IconLinkProps) => {
  return (
    <IconLinkWrapper href={to} target="_blank" rel="noreferrer noopener">
      <Icon src={icon} alt={text} />
      {text}
    </IconLinkWrapper>
  )
}

export default IconLink
